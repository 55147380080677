.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .header_fl {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 16px;
        svg {
            width: 20px;
            height: 20px;
            fill:currentColor;
            color: rgb(48, 122, 252);
        }
        .title {
            margin: 0 0 0 6px;
        }
    }
    .header_fr {
        .avater {
            position: relative;
            .avaterInfo {
                width: 100px;
                color: #000;
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 999;
                background: #fff;
                box-shadow: 0 0 5px 1px #808080;
                border-radius: 4px;
                div {
                    height: 40px;
                    line-height: 40px;
                    padding: 2px 10px;
                    cursor: pointer;
                    &:hover {
                        background: #f5f5f5;
                    }
                    &:nth-child(1) {
                        border-bottom: 1px solid #ccc;
                    }
                    span:nth-child(1) {
                        margin: 0 4px 0 0;
                    }
                }
            }
        }
    }
}