@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
  // min-width: 1500px;
}

.w-e-toolbar {
  z-index: 999 !important;
}

.w-e-text-container {
  z-index: 0 !important;
}

.settled {
  padding: 0 15px;
  height: 100%;
  background: url(./assets/settledBg.png) no-repeat;
  background-size:100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  .box {
    width: 500px !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    

    h1 {
      text-align: center;
    }
  }
}