.slider {
    // scrollbar-width: none;
    // -webkit-scrollbar{display:none;}
    // overflow-y: scroll;
    svg {
        width: 20px;
        height: 25px;
        fill: currentColor;
        color: #fff;
    }

    .ant-menu-item-icon {
        line-height: 0 !important;
    }

 
}
.leftScroll {
    overflow-y: scroll;
}
.slider_btn {
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 20px;
    right: 30px;

    & svg {
        width: 16px;
        height: 20px;
    }
}
.slider_btn2 {
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 20px;
    right: 10px;

    & svg {
        width: 16px;
        height: 20px;
    }
}
// ::-webkit-scrollbar {
//     display: none;
//     /* Chrome Safari */
// }